<template>
  <Bar
    v-if="chartData"
    :id="chartId"
    :key="updated"
    ref="chart"
    :data="chartData"
    :options="{
      ...options,
      ...(customOptions || {}),
      animation: animation === false ? animation : animation || options?.animation || false,
    }"
  />
</template>

<script setup lang="ts">
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  registerables,
  Title,
  Tooltip,
  type AnimationSpec,
} from 'chart.js'
import { onBeforeUnmount, ref, watch, onMounted } from 'vue'
import { Bar } from 'vue-chartjs'
import useChartJSRender, { defaultProps } from '~/composables/useChartJSRender'
import type { ChartJSRenderProps } from '~/composables/useChartJSRender'

ChartJS.register(...registerables)
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, LineElement)

Tooltip.positioners.custom = function (chartElements, coordinates) {
  if (!chartElements.length) return
  let offset
  // adjust the offset left or right depending on the event position
  if (chartElements[0]._chart.width / 2 > coordinates.x) {
    offset = 20
  } else {
    offset = -20
  }

  return {
    x: coordinates.x + offset,
    y: chartElements[0]._chart.height - chartElements[0]._chart.height * 0.2,
  }
}

const props = withDefaults(
  defineProps<
    ChartJSRenderProps & {
      chartData: any[] | object
      showLegend?: boolean
      showTotals?: boolean
      animation?: false | AnimationSpec<'bar'>
      customOptions?: any
    }
  >(),
  {
    ...defaultProps,
    showLegend: false,
    showTotals: true,
  },
)

const updated = ref(0)
const indexAxis = ref('x')
const pluginCrosshairEnabled = ref(false)
const showTotal = ref(false)
const chart = ref<{ chart: ChartJS } | null>(null)

showTotal.value = props.isStacked && props.showTotals

const { options } = useChartJSRender(props, { indexAxis, pluginCrosshairEnabled, showTotal })

const onResize = () => {
  if (window.innerWidth >= 640 && window.innerWidth < 800) {
    updated.value++
  }
}

onMounted(() => {
  window.addEventListener('resize', onResize)
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', onResize)
})

watch(props, (newValue) => {
  if (!chart.value) return
  chart.value.chart.data = newValue.chartData
  chart.value.chart.update()
})
</script>

<style scoped>
#chartjs-tooltip ::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -10px;
  width: 0;
  height: 0;
  border-top: solid 5px rgb(0 0 0 / 80%);
  border-left: solid 5px transparent;
  border-right: solid 5px transparent;
}
</style>
